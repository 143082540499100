@import '../../marketplace.css';

.root {
  background-color: var(--matterColorLight);
}

.pageTitle {
  text-align: center;
}

.staticPageWrapper {

}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}

.scannerContainer > section > div {
  width: 100%;
  box-shadow: var(--boxShadowScanner) !important;
}

.overlayContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  overflow: hidden;
}