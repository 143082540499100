@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  color: var(--matterColorLight);
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }

}

.subTitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 0;
  font-weight: 400;
  padding-top: 8px;
  color: var(--matterColorLight);
}


.textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--viewportMedium) {
    width: 40%;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  width: 100%;
/* 
  @media (--viewportMedium) {
    flex-direction: row;
  } */
}

.stepNumber {
  height: 26px;
  width: 26px;
  display: inline-block;
  text-align: center;
  background: var(--marketplaceColor);
  margin-right: 8px;
  border-radius: 50%;
  color: var(--matterColorLight);
  font-size: 14px;
  /* margin-bottom: 6px; */
  /* line-height: 28px; */
  position: relative;
  bottom: 2px;
  line-height: 26px;
}

.step {
  @media (--viewportMedium) {
    /* width: 324px; */
    /* margin-right: 40px; */
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    /* margin-right: 0; */
  }
}

.stepTitle {
  @apply --marketplaceListingAttributeFontStyles;
  /* color: var(--matterColorLight); */
  color: var(--marketplaceColor);
  font-weight: 600;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.stepDescription {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorLight);
  font-weight: 400;
  margin-top: 4px;
}

.createListingLink {
  margin-top: 36px;

  @media (--viewportMedium) {
    margin-top: 52px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stepsImageContainer {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 24px;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
  
  @media (--viewportMedium) {
    margin-top: unset;
    width: 55%;
  }

  @media (--viewportLarge) {
    margin-top: unset;
    width: 55%;
  }

  /* margin-top: -100px;
  margin-right: -56px; */

}

.stepImage {
  width:100%;
  height: auto;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px;
  box-shadow: var(--boxShadowButton);
  overflow: hidden;
}

.contentWrapper {
  position: relative;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.imageWrapper {
  padding-bottom: calc(2 / 3 * 100%);
}