@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;

  margin-top: 0px;
  max-width: 735px;
  position: absolute;

  @media (--viewportMedium) {
    /* display:none; */
    font-size: 28px;
  }
}

.sectionRow {
  /* @apply --clearfix; */
  /* border-top: 1px solid var(--matterColorNegative); */
  border-top: 0px;
  border-bottom: 0px;
  padding: 0 0 0px 0!important;

  /* padding-bottom: 48px;
  padding-top:48px; */

  @media (--viewportMedium) {
    padding: 8px 0 !important;
  }
}

/* .locationsWrapper::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
  /* display: none; */
}

.locationsWrapper {
  display: flex;
  width: 100%;
  /* display: -webkit-box; */
  flex-wrap: nowrap;
  /* padding: 0 0 20px; */
  padding-top: 56px;
  
  @media (--viewportMedium) {
    padding-top: 64px;
  }

}

.locations {
  display: flex;
  min-width: 100%;

  justify-content: space-between;

  flex-wrap: unset;

  margin: 0 -10px;

  @media (--viewportMedium) {
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: 12px;
  } 
}

.location {
  width: 100%;
  /* height: 100% !important; */
  min-width: 92.5%;
  max-width: 92.5%;
  margin-bottom: 8px;
  position: relative;
  
  padding: 0 10px;

  @media (--viewportMedium) {
    padding: 0;
    min-width: 23.5%;
    max-width: 23.5%;
    margin-bottom: 24px;
  }

}

.locationWrapper {
  height: 100%;
  overflow: hidden;
  border-radius: 8px !important;
/*   
  box-shadow: var(--boxShadowPromotion);  */

  @media (--viewportMedium) {
    transition: var(--transitionStyleButton);

    &:hover {
      text-decoration: none;
      transform: scale(1.02);
      box-shadow: var(--boxShadowSectionLocationHover);
    }
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  /* 13:6 Aspect Ratio */
  /* padding-bottom: calc(6 / 13 * 100%);  */
  /* 3:2 Aspect Ratio */
  /* padding-bottom: calc(2 / 3 * 100%);  */
  padding-bottom: 50%;

  @media (--viewportMedium) {
    /* padding-bottom: calc(6 / 13 * 100%); */
    padding-bottom: calc(3 / 5 * 100%);
  }

}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 4px; */
  object-fit: cover;
}

.aspectWrapper::after {
  content: '';
  display: block;
  height: 40%;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--boxShadowInlineWebKit);
  background: var(--boxShadowInline);

}

.linkText {
  position: absolute;
  bottom: 18px;
  left: 24px;
  @media (--viewportMedium) {
    bottom: 18px;
    left: 14px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.sectionTitle {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  color: var(--matterColorLight);
  margin-bottom:2px;
  /* @media (--viewportMedium) {
    @apply --marketplaceH4FontStyles;
  }
  @media (--viewportLarge) {
    @apply --marketplaceH3FontStyles;
  } */
}
