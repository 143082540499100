@import '../../marketplace.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
}

.filtersWrapper {
}

.footer {
  display: flex;
}

.resetAllButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 24px auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.cancelButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  max-width: 150px;
  border-radius: 4px;
  min-height: 48px;

  /* Layout */
  margin: 24px 24px 0 24px;

  /* Override button styles */
  outline: none;
  text-decoration: none !important;
  padding: 8px 24px;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.applyButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  max-width: 150px;
  border-radius: 4px;
  border: 0px;
  min-height: 48px;

  /* Layout */
  margin: 0;
  padding: 8px 24px;
  margin-top: 24px;

  /* Override button styles */
  outline: none;
  text-decoration: none !important;

  &:focus,
  &:hover {
    background: var(--marketplaceColorDark);
  }
}
