@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.addOnTtitle {
  @apply --marketplaceListingAttributeFontStyles;
  font-size: 16px;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.addOn {
  margin-left: 24px;
  @media (--viewportMedium) {
    margin-left: 0px;
  }
}

.fieldsPromos {
  padding: 0 24px;
  margin-bottom: 38px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.fieldsPayment {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.voucherContainer {
  margin-top: 24px;
  width: 100%;
  display: flex;
}

.voucherInput {
  flex-grow: 1;
}

.root .voucherButton {
  @apply --marketplaceTabNavHorizontalFontStyles;
  margin-left: 26px;
  max-width: 100px;
  align-self: flex-end;
  min-height: 45px;
  
}

.voucherError {
  font-size: 14px;
  color: var(--failColor);
  margin: 0;
  display: inline-block;
/* 
  @media (--viewportLarge) {
    margin: 0px 0 14px;
  } */
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 24px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 32px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 32px;
    margin-bottom: 10px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 10px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: 30px 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 30px;
    margin-bottom: 14px;
  }
}

/* .submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  
  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
} */

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  /* background-color: var(--matterColorLight); */

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}
.bookButton {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColor);
  max-height: 66px;
  
  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }
  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.freeBookButton {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--successColor);
  max-height: 66px;
  
  &:hover,
  &:focus {
    background-color: var(--successColorDark);
  }
  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.inlineBookButton {
  margin-left: -8px;
  display: inline-block;
}

.bookIcon {
  margin-left: 8px;
}

.loyaltyIcon {
  color: #FCBC04;
  margin-right: 4px;
}