@import '../../marketplace.css';

:root {

}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.iconContainer {
  display: flex;
  margin-left: 8px;
  padding-top: 2px;
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--matterColorLight);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.mainContainer {
  background: var(--matterColorLight);
  padding: 24px 24px;
  border-radius: 8px;
  box-shadow: var(--boxShadowPopup);

  @media (--viewportMedium) {
    padding: 32px 32px;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.keywordContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom:24px;

  @media (--viewportMedium) {
    width: 40%;
    margin-bottom:unset;
  }
}

/* .keywordContainer > input {
  font-size: 18px;
  font-weight: 400;
} */

.themeContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom:32px;
  @media (--viewportMedium) {
    width: 30%;
    margin-bottom:unset;
  }
}

/* .themeContainer > select {
  font-size: 18px;
  font-weight: 400;
} */

.buttonContainer {
  width: 100%;
  height: 100%;
  margin-top: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (--viewportMedium) {
    width: 25%;
  }
}