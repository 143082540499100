@import '../../marketplace.css';

:root {

}

.mainContainer {

}

.formContainer {
  display: flex;
  border: 1px solid #FCBC04;
  border-radius: 4px;
  padding-left: 6px;

  @media (--viewportMedium) {

  }
}

.keywordContainer {

}

.emailInput{
  flex-grow: 1;
  /* Font */
  @apply --marketplaceTinyFontStyles;
  height: 42px;
  /* line-height: unset; */
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 18px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.buttonContainer {
  font-size:13px;
  font-weight: 500;
  width: 120px;
  min-height: 0;
}

.subscribeSuccess {
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColorDark);
  float: right;
  margin: 8px 0 0 0;
  font-weight: 500;
}

.subscribeError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  float: right;
  margin: 8px 0 0 0;
  font-weight: 500;
}

.noSubscribe {
  display:none;
}