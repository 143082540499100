@import '../../marketplace.css';

:root {

}

.root {
  display: flex;
  margin-top: 50px;
  flex-direction: column;
}

.mobileRoot {
  display: flex;
  margin-top: 50px;
  /* flex-direction: row; */
  flex-direction: column;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mobileColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mobileColumn:nth-child(2) {
  /* margin: 0 10px; */
}

.imageWrapper {
  width: 100%;
  margin-bottom: 12px;
  /* cursor: pointer; */
}

.image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: var(--matterColorCarouselModalScroll);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}