@import '../../marketplace.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;
}

.info {
  flex: 1;
}

.heading {
  @apply --marketplaceH2FontStyles;
  margin: 17px 0 0 0;

  @media (--viewportMedium) {
    margin: 15px 0 0 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  
  @apply --marketplaceH4FontStyles;

  @media (--viewportMedium) {
    margin: 18px 0 0 0;
  }
}

.mobileBio {
  font-weight: 400;
  margin-left: -126px;
  margin-top: 48px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;
  font-weight: 400;
  
  @media (--viewportMedium) {
    margin-top: 16px;
    display: block;
  }
}

.showMore {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0 0 0 5px;
}

.links {
  @apply --marketplaceH4FontStyles;
  margin-top: 30px;
  margin-left: -130px;
  display: flex;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
    display: block;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  @apply --marketplaceH4FontStyles;
  display: none;
  margin-bottom: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  @apply --marketplaceH4FontStyles;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  /* margin: 0; */

  border: var(--matterColor) 1px solid;
  padding: 12px 16px;
  color: var(--matterColor);
  border-radius: 4px;
  font-weight: 500;
  /* margin-left: 4px; */
  margin-right: 5px;
  flex: 50%;

  @media (--viewportMedium) {
    padding: 12px 20px;
  }
}

.businessEnquiry {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  /* margin: 0; */

  border: var(--reminderColor) 2px solid;
  background: var(--reminderColor);
  padding: 12px 16px;
  color: var(--matterColorLight);
  border-radius: 4px;
  font-weight: 500;
  margin-left: 5px;
  /* margin-right: 4px; */
  flex: 50%;
  text-align: center;

  @media (--viewportMedium) {
    padding: 12px 20px;
  }
}

.userVerifiedContainer {
  margin: 10px 0 24px 0 !important;
  @apply --marketplaceH4FontStyles;
  font-weight: 400;
}

.verifiedIcon {
  margin-bottom: -2px;
  margin-right: 4px;
  width: 20px !important;
  height: 20px !important;
  color: var(--marketplaceColor);
}