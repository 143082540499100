@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}






/* Main Container */
.mainContainer {
  border-radius: 4px;
  background-color: var(--matterColorLight);
  /* box-shadow: 0 2px 4px 0 rgba(184,184,184,.5); */
  box-shadow: var(--boxShadow);
  position: relative;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Author */
.authorContainer {
  position: absolute;
  right: 18px;
  bottom: -32px;
}

.authorImage {
  width: 50px;
  border-radius: 50%;
  /* border-width: 3px; */
  border: 3px solid var(--matterColorLight);
  background: var(--matterColorLight);
}

/* Online Experience */
.experienceContainer {
  /* @apply --marketplaceH6FontStyles; */
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  font-size: 12px;
  position: absolute;
  display: flex;
  top: 12px;
  
  border: 0;
  text-align: center;
  padding: 4px 10px;
  border-radius: 4px;
  /*  */
  /*  */
  left: 12px;
}

.experienceTitle {
  margin-right: 1px;
}

.experienceIcon {
  margin-right: 6px;
}

.titleContainer {
  height: 86px;
  max-height: 86px;
  margin-bottom: 10px;
  position: relative;
}

/* Title */
.mainInfo {
  width: 76%;
  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-size: 18px;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.info {
  /* Layout */
  /* display: flex;
  flex-direction: row; */
  /* padding: 14px 0 2px 0; */
  padding: 14px 15px;
}

.subTitle {
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: 6px;
  color: var(--matterColorSubTitle);
  font-weight: 400;
}

.subTitleSeparator {
  padding: 0 4px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--matterColorDark);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  /* @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  } */
}

.originalPriceValue {
  @apply --marketplaceDiscountFontStyles;
}

.priceContainer {
  margin-top: auto;
}

.price {
  /* Layout */
  display: flex;
  position: relative;
  height: 40px;
  max-height: 40px;
  /* margin-bottom: 4px; */
  /* margin-top: 30px; */
  /* flex-direction: column;
  justify-content: flex-start; */
  /* flex-shrink: 0; */
  /* margin-right: 18px; */
}

.newRatingBadge {
  color: var(--matterColorLight);
  border-radius: 4px;
  /* display: inline-block; */
  /* margin-top: 10px; */
  background: var(--badgeColor);
  font-weight: 500;
  font-size: 12px;
  /* line-height: 18px; */
  letter-spacing: 0;
  /* margin-top: 10px; */
  padding: 4px 10px;
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Rating */
.reviewContainer {
  position: absolute;
  right: 0;
  bottom: -1px;
}

.reviewRating {
  position: relative;
  /* top: -1px; */
}

.reviewAverageCount {
  @apply (--marketplaceH5FontStyles);
  color: var(--matterColorDark);
  font-weight: 600;
  margin-left: 6px;
}

.reviewCount {
  @apply (--marketplaceH5FontStyles);
  color: var(--matterColorDark);
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}