@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  /* min-height: 300px;
  height: 80vh; */
  /* max-height: 700px; */
  padding: 0;

  /* @media (--viewportMedium) {
    min-height: 500px;
    height: 75vh;
    max-height: none;
  } */

  @media (--viewportLarge) {
    max-height: 1000px;
    min-height: 600px;
    height: calc(85vh - var(--topbarHeightDesktop));
  }
}

.hero:after {
  content: '';
  position: absolute;
  background: var(--matterColorOverlay);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
/* .section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
} */

/* Every other section has a light background */
/* .section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
} */

.sectionContent {
  /* margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px; */

  margin: 26px 24px 32px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: 32px 24px 8px 24px;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    padding: 0 56px 0 56px;
    /* margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto; */
    margin: 32px auto 12px auto;
  }

  /* @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  } */
}

/* .sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
} */

/* A bar on top of light sections */
/* .section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px; */

  /* Place the bar on top of .sectionContent top margin */
  /* position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
} */






/* New Listing */
/* Listing */
.sectionRow {
  /* @apply --clearfix; */
  /* border-top: 1px solid var(--matterColorNegative); */
  border-top: 0px;
  border-bottom: 0px;
  padding: 0 !important;

  /* padding-bottom: 48px;
  padding-top:48px; */

  @media (--viewportMedium) {
    padding: 16px 0 !important;
  }
}

.listingsTitle {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  position: relative;
  /* padding-left: 16px; */
  margin-top: 0;
  margin-bottom: 28px;
  /* border-bottom: 10px solid #FCBC04; */
  @media (--viewportMedium) {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 30px;
  }
}


.listings {
  overflow: unset !important;
  padding-top: 56px;
  /* padding-bottom: 64px; */
  /* flex-direction: column; */

  @media (--viewportMedium) {
    flex-direction: row;
    padding-top: unset;
    padding-bottom: unset;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
  }

  @media (--viewportLarge) {
    flex-wrap: wrap;
    overflow-x: visible !important;
    overflow-y: visible !important;
    justify-content: space-between;
  }
}

.titleContainer {
  position: absolute;
  left: 0;
  width: 100%;

  @media (--viewportMedium) {
    position: relative;
    left: unset;
    width: unset;
  }
}

.listing {

  min-width: 92.5%;
  max-width: 92.5%;
  flex: 1 1 92.5%;
  margin-bottom: 12px;
  padding-right: 24px;

  @media (--viewportMedium) {
    min-width: 40%;
    max-width: 40%;
    flex: 1 1 40%;
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    min-width: 32%;
    max-width: 32%;
    flex: 1 1 32%;
    margin-bottom: 24px;
    padding: 0;
  }

  @media (--viewportLargeWithPaddingsAndMargins) {
    min-width: 23.5%;
    max-width: 23.5%;
    flex: 1 1 23.5%;
    margin-bottom: 24px;
    padding: 0;
  }

}

.listingHeaderTitle {
  margin-left: 24px;
  @media (--viewportMedium) {
    margin-left: unset;
  }
}

.paddingContainer {
  margin-right: 24px;
  @media (--viewportMedium) {
    margin-right: unset;
  }
}

/* .listings > li:nth-of-type(1n+5) {
  @media (--viewportLarge) {
    display: none;
  }
  @media (--viewportLargeWithPaddingsAndMargins) {
    display: unset;
  }
}  */

.listings::after {
  content: "";
  flex: auto;

  @media (--viewportLarge) {
    min-width: 32%;
    max-width: 32%;
    flex: 1 1 32%;
  }

  @media (--viewportLargeWithPaddingsAndMargins) {
    min-width: 23.5%;
    max-width: 23.5%;
    flex: 1 1 23.5%;
  }

}

/* .listings > li:nth-child(3n) {
  @media only --viewportLarge and --viewportLargeWithPaddingsAndMargins {
    padding-right: 0px;
  }
} */

/* .listings > li:nth-child(4n) {
  @media (--viewportLargeWithPaddingsAndMargins) {
    padding-right: 0px !important;
  }
} */

.promotelisting {

  @media (--viewportLargeWithPaddingsAndMargins) {
    min-width: 23.5%;
    max-width: 23.5%;
    flex: 1 1 23.5%;
    margin-bottom: 24px;
  }

}

.promoteListings {
  overflow: unset !important;
  padding-top: 56px;
  /* padding-bottom: 64px; */

  @media (--viewportMedium) {
    padding-top: unset;
    padding-bottom: unset;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
  }

  @media (--viewportLarge) {
    /* flex-wrap: wrap; */
    /* overflow-x: visible !important;
    overflow-y: visible !important; */
    /* justify-content: space-between; */
  }
}

.promoteListingFullSearchContainer {
  position: absolute;
  width: 100%;
  display: flex;
  left: 0;
  margin-top: -48px;

  @media (--viewportMedium) {
    display: none;
  }
}

.buttonContainer {
  @media (--viewportLarge) {
    display: none !important;
  }
}

.readMoreTitle {
  margin-left: auto;
  /* display: none;
  @media (--viewportMedium) {
    display: unset;
  } */
}

.fullPageReadMoreContainer {
  margin-top:2px !important;
}




/* Experience Type */
.sectionLocationContainer {
  margin-bottom: 32px;
  margin-top: 48px;
  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 72px;
  }
}







/* Platform Value */
.sectionPlatformValueContainer {
  margin-top: 32px;
  padding-top: 32px;
  background: var(--matterColor);
  padding-bottom: 32px;

  @media (--viewportMedium) {
    margin-top: 48px;
    padding-top: 48px;
    padding-bottom: 48px;
    margin-bottom: 32px;
  }
}