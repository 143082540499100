@import '../../marketplace.css';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prev,
.next {
  display: block;

  /* Dimensions */
  padding-top: 26px;
  padding-bottom: 26px;

  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
  /* padding-right: 33px; */
  margin-right: 20px;
  padding-right: 13px;
  margin-left: auto;
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  /* padding-left: 33px; */
  margin-left: 20px;
  padding-left: 13px;
  margin-right: auto;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--matterColorDark);
  stroke: var(--matterColorDark);

  padding: 14px !important;
  border: 0px !important;
  outline: 0px !important;
  border-radius: 10% !important;
  width: 40px !important;
  height: 40px !important;
  background-color: var(--matterColorLight) !important;

  border-style: solid !important;
  border-width: 1px !important;
  background-clip: padding-box !important;
  border-color: var(--matterColorBorder) !important;
  box-shadow: 0px 0px 0px 1px transparent, 0px 0px 0px 4px transparent, var(--boxShadowLight) !important;

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.disabled,
.disabled:hover {
  fill: var(--matterColorNegative);
  stroke: var(--matterColorNegative);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.toPageLink {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--matterColor);

  &:hover {
    text-decoration: none;
  }
}

.currentPage {
  /* Dimensions */
  padding: 21px 5px 18px 5px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: var(--matterColorDark);

  /* Color */
  color: var(--matterColorDark);
}

.paginationGap {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--matterColor);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}
