@import '../../marketplace.css';

:root {

}


.formContainer {
  display: flex;
  /* border: 1px solid #FCBC04; */
  /* border-radius: 4px;
  padding-left: 6px; */
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 38px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 32px;
  }
}

.selectContainer {
  width: 100%;
  margin-left: auto;

  @media (--viewportLarge) {
    width: unset;
  }
}

.selectRoot {
  padding-right: 32px;
  font-size: 18px;
  padding-bottom: 6px;
}