@import '../../marketplace.css';

:root {

}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
  /* display: none; */
}

/* Section */
.sectionRow {

  border-top: var(--borderTop);
  
  padding: 48px 24px !important;
  margin-bottom: 0px !important;

  @media (--viewportMedium) {
    padding: 48px 0 !important;
    margin-bottom: 0px;
  }
}

.sectionHeaderTitle::before {
  content: '';
  position: absolute;
  top: 55%;
  left: 0;
  width: 5px;
  height: 22px;
  background-color: var(--marketplaceColor);
  border-radius: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sectionHeaderTitle {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  position: relative;
  padding-left: 16px;
  margin-top: 0;
  margin-bottom: 28px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.sectionTitleContainer {
  position: relative;
  display: flex;
}

.headerContainer {
  display: inline-block;
}

.buttonContainer {
  display:none;
  /* position: absolute; */
  /* right: 0px; */

  @media (--viewportMedium) {
    margin-left: 24px;
    display: inline-block;
  }
}

.prev,
.next {

  cursor: pointer;
  display: inline-flex !important;
  
  border-radius: 10% !important;
  border: 0px !important;
  outline: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  color: var(--matterColor) !important;
  background-color: var(--matterColorLight) !important;

  /* transition: box-shadow 0.2s ease, -ms-transform 0.25s ease, -webkit-transform 0.25s ease, transform 0.25s ease !important; */
  width: 32px !important;
  height: 32px !important;

  border-style: solid !important;
  border-width: 1px !important;
  background-clip: padding-box !important;
  border-color: var(--matterColorBorder) !important;
  box-shadow: 0px 0px 0px 1px transparent, 0px 0px 0px 4px transparent, var(--boxShadowLight) !important;

/* 
  @media (--viewportLarge) {
    opacity: 0.5;
    transition: var(--transitionStyleButton);

    &:hover {
      opacity: 1;
    }
  } */
}

.prev {
  left: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="9" height="12" style=" display: block; fill: currentcolor;" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="m13.7 16.29a1 1 0 1 1 -1.42 1.41l-8-8a1 1 0 0 1 0-1.41l8-8a1 1 0 1 1 1.42 1.41l-7.29 7.29z" fill-rule="evenodd"/></svg>');
  /* background-position: center left 20px; */
  background-position: center left 1.5vw;

  @media (--viewportMedium) {
    background-position: center left 0.9vw;
  }

  @media (--viewportLarge) {
    background-position: center left 0.6vw;
  }

}

.prevContainer {
  margin-right: 10px;
  display:inline-block;
}

.nextContainer {
  display:inline-block;
}

.next {
  right: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="9" height="12" style="display: block; fill: currentcolor;" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="m4.29 1.71a1 1 0 1 1 1.42-1.41l8 8a1 1 0 0 1 0 1.41l-8 8a1 1 0 1 1 -1.42-1.41l7.29-7.29z" fill-rule="evenodd"/></svg>');
  /* background-position: center right 20px; */
  background-position: center right 1.8vw;

  @media (--viewportMedium) {
    background-position: center right 1.1vw;
  }

  @media (--viewportLarge) {
    background-position: center right 0.7vw;
  }
  /* @media (--viewportMedium) {
    /* background-position: center right 1vw; */
    /* background-image: url('data:image/svg+xml;utf8,<svg width="20" height="31" viewBox="0 0 20 31" xmlns="http://www.w3.org/2000/svg"><path d="M1.3332 31c-.37866 0-.75333-.1550012-1.01867-.4585452-.47467-.5425042-.404-1.3575521.15734-1.8199723l16.12944-13.2216017L.47187 2.27827915C-.08947 1.81585893-.16014 1.000811.31453.45701515.79187-.08807237 1.63187-.15394788 2.19454.3045973L19.528 14.5130399c.3.2467102.472.607088.472.9868409 0 .3797529-.172.7401307-.472.9868409L2.19454 30.6951643C1.94388 30.8992492 1.63721 31 1.3332 31" fill="%23FFF" fill-rule="evenodd"/></svg>'); 
 
  } */
}

.listings {

  display: -webkit-flex !important;
  display: flex !important;
  height: 100% !important;
  list-style: none !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  padding-left: 2px !important;
  /* padding-right: 2px !important; */
  -webkit-overflow-scrolling: touch !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  min-width: 100% !important;
  -webkit-scroll-snap-type: x mandatory !important;
  -ms-scroll-snap-type: x mandatory !important;
  scroll-snap-type: x mandatory !important;
  width: 0px;
  /* &:hover {
    overflow-x: scroll !important;
  } */

  /* @media (--viewportMedium) {
    flex-wrap: wrap;
  } */
}

.listingCard {
  min-width: 100%;
}

.listing {

  min-width: 92.5%;
  max-width: 92.5%;
  flex: 1 1 92.5%;
  box-sizing: border-box;
  -webkit-box-align: center;
  display: flex;
  /* margin-top: 24px; */
  margin-bottom: 12px;
  padding-right: 24px;
  align-items: center !important;

  /* width: 100%; */

  @media (--viewportMedium) {
    min-width: 40%;
    max-width: 40%;
    flex: 1 1 40%;
  }

  @media (--viewportLarge) {
    min-width: 30%;
    max-width: 30%;
    flex: 1 1 30%;
  }

}

.readMoreContainer {
  display: flex;
  margin-left: auto;
}

.searchLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  text-decoration: underline;
  font-weight: 600;
  margin: 0;
  margin-top: 6px;
}

.paddingContainer {
  
}

.fullPageSearchContainer {
  margin-top: 12px;

  @media (--viewportMedium) {
    display: none;
  }
}

.fullPageSearch {
  @apply --marketplaceH4FontStyles;
  margin: auto;
  padding: 12px 36px;
  background: var(--marketplaceColor);
  display: block;
  border-radius: 4px;
  color: var(--matterColorLight);
  text-align: center;
  text-decoration: none !important;
  outline: none;
  width: 100%;

  &:hover {
    background: var(--marketplaceColorDark);
  }
}