@import '../../marketplace.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

.container {
  display: flex;
  flex-direction: column;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceModalHelperLink;
}

.helperMVLink {
  @apply --marketplaceModalHelperLink;
  color: var(--marketplaceColorDark);
  font-size: 20px;
  line-height: 32px;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.helperMVText {
  @apply --marketplaceModalHelperText;
  color: var(--marketplaceColorDark);
  font-size: 20px;

  &:hover {
    color: var(--marketplaceColor);
  }
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.bottomMVWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: center;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}

.invisibleIcon {
  visibility: hidden;
}

.inputCodeWrapper {
  margin: 60px 0 90px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputCode {
  width: 80px;
  margin-top: 15px;
  font-size: 20px;
  line-height: 32px;
  text-align: center;

  &::placeholder {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }
}

.phoneImage {
  width: 134px;
  height: 134px;
}

.verifyPhoneSuccess {
  font-size: 30px;
  font-weight: bold;
  color: var(--marketplaceColor);
}

.resendSuccessMessage {
  font-size: 18px;
  margin-top: 20px;
  color: var(--marketplaceColor);
}

.logo {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.text {
  color: var(--matterColorDark);
  margin-top: 20px;
  transition: opacity 0.3s;
  cursor: pointer;
  
  &:hover {
    color: var(--marketplaceColor);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  color: var(--marketplaceColorDark);
  margin-top: 20px;
  transition: opacity 0.3s;
  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 140px; */
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 18%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--marketplaceColorDark) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.header {
    @apply --marketplaceH1FontStyles;
    color: var(--marketplaceColorDark);
    font-weight:bold;
    font-size: 48px;
    padding-top:30px;

    @media (--viewportMedium) {
        padding-top:0px;
    }
}

.message {
    @apply --marketplaceH2FontStyles;
    color: var(--matterColorDark);
    /*  */
    font-weight: 400;
    line-height: 1.2;
    font-size: 30px;
    margin-top: 12px;
    margin-bottom: 25px;
  /*  */

  @media (--viewportMedium) {
    margin-top: 0px;
  }
}