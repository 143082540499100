@import '../../marketplace.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}







/* ContentContainer */
.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 0px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  /* margin-bottom: 23px; */
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 50px;
    /* margin-bottom: 51px; */
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    /* margin-bottom: 0px; */
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.secondaryContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 80px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.secondaryContent {
  flex-basis: 100%;
  /* margin-bottom: 23px; */

  @media (--viewportMedium) {
    /* margin-top: 50px;
    margin-bottom: 51px; */
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    /* margin-top: 50px;
    margin-bottom: 0px; */
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: 100%;
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }
}







/* Booking Panel */
.bookingPanel {

  @media (--viewportLarge) {
    display: block;
    margin-top: 95px;
    /* border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative); */

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }

  @media (--viewportLargeWithPaddings) {
    margin-left: 12px;
  }

  @media (--viewportLargeWithPaddingsAndMargins) {
    margin-left: 60px;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 53px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;
  padding-bottom: 15px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 10px;
}







/* Section */
.sectionRow {

  border-top: var(--borderTop);
  
  padding: 48px 24px !important;
  margin-bottom: 0px !important;

  @media (--viewportMedium) {
    padding: 48px 0 !important;
    margin-bottom: 0px;
  }
}

.sectionHeaderTitle::before {
  content: '';
  position: absolute;
  top: 55%;
  left: 0;
  width: 5px;
  height: 22px;
  background-color: var(--marketplaceColor);
  border-radius: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sectionHeaderTitle {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  position: relative;
  padding-left: 16px;
  margin-top: 0;
  margin-bottom: 28px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.sectionDescription {
  @apply --marketplaceMessageFontStyles;

  margin-top: 0;
  margin-bottom: 0;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.sectionImageList {
  padding: 0px 24px 48px !important;
  margin-bottom: 0px !important;
  margin-top: -18px !important;;

  @media (--viewportMedium) {
    padding: 0 0 48px 0 !important;
    margin-bottom: 0px;
  }
}






/* Basic Information */
.basicInfoContainer {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  width: 100% !important;
  margin-bottom: -12px;
}

.basicInfoRow {
  min-width: 100%;
  flex: 1 1 50%;
  box-sizing: border-box;
  -webkit-box-align: center;
  display: flex;
  margin-bottom: 12px;
  margin-top:12px;
  padding-right: 16px;
  -webkit-align-items: center !important;
  align-items: center !important;

  @media (--viewportMedium) {
    min-width: auto !important;
    max-width: 50% !important;
  }
}

.basicInfoIcon {
  display: block !important;
  height: 26px !important;
  width: 26px !important;
  box-sizing: border-box !important;
}

.basicInfoTitle {
  @apply --marketplaceMessageFontStyles;

  margin-left: 16px !important;
  display: inline-block !important;
  box-sizing: border-box;
}







/* Service */
.serviceContainer {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  width: 100% !important;
  margin-bottom: 8px;
}

.serviceRow {
  border: var(--normalBorder);
  border-radius: 8px !important;
  padding: 26px 36px !important;
  margin-right: 20px;
  margin-bottom: 20px;
  /* max-width: 25%;
  flex: 0 0 20%; */
}

.serviceContentContainer {
  text-align: center;
  margin-left: -4px;
}

.serviceIcon {
  /* display: block; */
  height: 32px;
  width: 32px !important;
  fill: currentcolor;
}

.serviceTitle {
  @apply --marketplaceH4FontStyles;
  margin-top: 8px;
  margin-bottom: 0px;
}






/* Feature */
.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}







/* Location */
.sectionLocationDescription {
  margin-top: 24px;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(60vh - 193px);
  width: 100%;
  /* height: 40%; */

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}







/* Notice */
.noticeContainer {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  width: 100% !important;
  
  margin-bottom: -32px;
/* 
  @media (--viewportMedium) {
    margin-bottom: -32px;
  } */
}

.noticeRow {
  min-width: 100%;
  flex: 1 1 50%;
  /* padding-right: 16px; */
  margin-bottom: 32px;

  @media (--viewportMedium) {
    min-width: auto !important;
    max-width: 50% !important;
  }
}

.noticeSubRow {
  width: 100%;
  @media (--viewportMedium) {
    width: 85%;
  }
}

.noticeTitle {
  @apply --marketplaceH4FontStyles;
  margin-bottom: 12px;
  margin-top: 0px;
  font-weight: 600;
}

.noticeDescription {
  @apply --marketplaceMessageFontStyles;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin-bottom: 0px;
}






/* Review */
.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}







/* Host */
.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}






/* Rating */
.reviewRating {
  position: relative;
  top: -1px;
}

.reviewAverageCount {
  @apply (--marketplaceH5FontStyles);
  font-weight: 600;
  margin-left: 6px;
}

.reviewCount {
  @apply (--marketplaceH5FontStyles);
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  text-decoration: underline;
  font-weight: 600;
  margin: 0;
  margin-right: 8px;
}

.totalSold {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  text-decoration: underline;
  cursor: default !important;
  font-weight: 600;
  margin: 0;
  margin-right: 8px;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.badge {
  background: var(--badgeColor);
  @apply --marketplaceH5FontStyles;
  padding: 5px 8px 5px;
  color: var(--matterColorLight);
  border-radius: 4px;
  display: inline-block;
  margin-top: 10px;

  margin-bottom: -15px;
  @media (--viewportMedium) {
    margin-bottom: 0px;
    padding: 3px 8px 3px;
  }

}







/* Host Experience */
/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .4);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .4);
} */

.listings {

  display: -webkit-flex !important;
  display: flex !important;
  height: 100% !important;
  list-style: none !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  padding-left: 2px !important;
  /* padding-right: 2px !important; */
  -webkit-overflow-scrolling: touch !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  min-width: 100% !important;
  -webkit-scroll-snap-type: x mandatory !important;
  -ms-scroll-snap-type: x mandatory !important;
  scroll-snap-type: x mandatory !important;
  width: 0px;
  /* &:hover {
    overflow-x: scroll !important;
  } */

  /* @media (--viewportMedium) {
    flex-wrap: wrap;
  } */
}

.listingCard {
  min-width: 100%;
}

.listing {

  min-width: 87.5%;
  max-width: 87.5%;
  flex: 1 1 87.5%;
  box-sizing: border-box;
  -webkit-box-align: center;
  display: flex;
  /* margin-top: 24px; */
  margin-bottom: 12px;
  padding-right: 24px;
  align-items: center !important;

  /* width: 100%; */

  @media (--viewportMedium) {
    min-width: 40%;
    max-width: 40%;
    flex: 1 1 40%;
  }

  @media (--viewportLarge) {
    min-width: 30%;
    max-width: 30%;
    flex: 1 1 30%;
  }

}

.firstTrailRow {
  
  padding: 0px 24px 48px !important;
  margin-bottom: 0px !important;

  @media (--viewportMedium) {
    border-top: var(--borderTop);
    padding: 48px 0 !important;
    margin-bottom: 0px;
  }
}

.firstTrailContainer {
  border-bottom: 1px solid var(--matterColorReminder) !important;
  border-left-style: solid !important;
  border-left-width: 8px !important;
  border-radius: 8px !important;
  border-right: 1px solid var(--matterColorReminder) !important;
  border-top: 1px solid var(--matterColorReminder) !important;
  padding: 32px !important;
  padding-bottom: 24px !important;
  position: relative !important;
  border-left-color: var(--reminderColor);
}

.firstTrailDescription {
  @apply --marketplaceMessageFontStyles;
  margin-bottom: 21px;
}

.firstTrailTitle {
  @apply --marketplaceH2FontStyles;
}