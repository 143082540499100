@import '../../marketplace.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

.container {
  display: flex;
  flex-direction: column;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.logo {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.verificationCode {
  margin: 20px auto;
}

.header {
    @apply --marketplaceH1FontStyles;
    color: var(--marketplaceColorDark);
    font-weight:bold;
    font-size: 48px;
    padding-top:30px;

    @media (--viewportMedium) {
        padding-top:0px;
    }
}

.failedHeader {
  color: var(--failColor) !important;
}

.message {
    @apply --marketplaceH2FontStyles;
    color: var(--matterColorDark);
    /*  */
    font-weight: 400;
    line-height: 1.2;
    font-size: 30px;
    margin-top: 12px;
    margin-bottom: 25px;
  /*  */

  @media (--viewportMedium) {
    margin-top: 0px;
  }
}

.text {
  color: var(--matterColorDark);
  margin-top: 20px;
  transition: opacity 0.3s;
  cursor: pointer;
  
  &:hover {
    color: var(--marketplaceColor);
  }
}

.text > span {
  color: var(--matterColorDark);
  font-weight: 400;
}