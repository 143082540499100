@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* This is the title for the Edit Photos component */
.title {
  margin-bottom: 19px;
  margin-right: 24px;
  
  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.helpCenter {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;

  display: inline-block;
  width: auto;
  min-height: auto;
  height: 41px;

  float: right;
  flex-shrink: 0;

  margin: 19px 0 0 auto;
  padding: 9px 16px 8px 16px;

  @media (--viewportMedium) {
    margin: 36px 0 0 auto;
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
}