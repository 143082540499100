@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.loading {
  padding: 0;
}

.aside {
  background: var(--matterColorLight);
  box-shadow: none;
  @media (--viewportLarge) {
   padding-right: 40px;
  }
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--matterColorNegative);
  flex-wrap: wrap;
  padding-bottom: 48px;
  padding-top: 12px;
  margin-top: 32px;
  justify-content: space-between;
  
  border: 1px solid var(--matterColorNegative);
  border-radius: 12px;
  padding: 34px 42px 40px;
  box-shadow: var(--boxShadowBookingForm);

  @media (--viewportMedium) {
    margin-top: 52px;
  }

  @media (--viewportLarge) {
    flex-direction: column;
    position: sticky;
    top: 102px;
    /* padding: 0; */
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;

  }
}




/* Avatar Information */
.avatar {
  width: 40px !important;
  height: 40px !important;
  margin-top: 6px;
  display: block !important;
  box-sizing: border-box !important;
  color: var(--marketplaceColor);
}

.pointHeader {
  @apply --marketplaceH3FontStyles;
  margin-top: 16px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-top: 8px;
    margin-bottom: 20px;
  }

}

.tagline {

  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  font-size: 32px;
  margin-top: 18px;
  margin-bottom: 12px;
  font-weight: 600;

  /* @media (--viewportMedium) {
    margin: 49px 0 0 0;
  } */


  @media (--viewportLarge) {
    margin-top: 20px;
    margin-bottom: 6px;
  }

}

.mobilePointContainer {
  width: 100%;
}

.verificationContainer {
  width: 100%;
  margin-bottom: -12px;
  margin-top: 26px;
  /* flex: 40%; */

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    width: 45%;
    max-width: 45%;
    margin-top: 0px;
  }

  @media (--viewportLarge) {
    display: unset;
    width: unset;
    margin-top: 26px;
    max-width: unset;
    padding-top: 24px;
    margin-top: 0px;
    border-top: 1px solid #EBEBEB !important;
    margin-bottom: 0px;
  }
}

.verificationHeader {
  @apply --marketplaceH3FontStyles;
  /* margin-top: 10px; */
  margin-bottom: 24px;
}

.reportAuthor {

  @apply --marketplaceH5FontStyles;
  text-decoration: underline;
  border-top: 1px solid #EBEBEB !important;
  padding-top: 28px;
  font-weight: 600;
  width: 100%;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    border-top: 0px !important;
    padding-top: 32px;
    font-weight: 600;
    margin-bottom: 48px;
  }

}

.reportAuthorHyperlink {
  color: var(--matterColor);
}

.badge {
  color: var(--matterColorLight);
  border-radius: 4px;
  background: var(--marketplaceColor);
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  padding: 8px 10px;
  display: inline-block;
  margin-top: 12px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  /* margin-bottom: 12px; */
/* 
  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  } */

  @media (--viewportLarge) {
    margin-top: 20px;
    padding: 4px 10px;
  }
}

.separator {
  margin:4px;
}

.membershipDisplayName {
  font-weight: 600;
}

.authorBadge {
  background: var(--matterColorDark) !important;
}







/* Heading */
.mainNavigation {
  border-left-width: 0px !important;
}

.mobileHeading {
  flex-shrink: 0;
  /* margin: 47px 0 0 0; */
  /* display: flex;
  
  flex-wrap: wrap; */
  /* flex: 100%; */

  /* max-width: 45%;
  width: 45%; */

  @media (--viewportMedium) {
    max-width: 45%;
    width: 45%;
  }

  @media (--viewportLarge) {
    display: none;
    max-width: unset;
    width: unset;
  }
}

.authorHeader {
  @apply --marketplaceH1FontStyles;
  display: flex;
  flex: 100%;
  margin-bottom: 0px;
  margin-top: 0px;
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 24px 0;
  }
}





/* Bio */
.bioHeader {
  @apply --marketplaceH3FontStyles;
  margin-top: 24px;

  @media (--viewportLarge) {
    display:none;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 24px 0 32px 0;
  @apply --marketplaceMessageFontStyles;

  @media (--viewportMedium) {
    margin: 24px 0 32px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 32px 0;
    /* max-width: 600px; */
  }
}

.membershipIntroContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 48px;
    margin-bottom: 0;
    /* margin-bottom: 48px; */
  }
}

.membershipIntro {
  max-width: 100%;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    max-width: 48%;
  }
}

.membershipIntroIcon {
  width: 40px !important;
  height: 40px !important;
  margin-top: 6px;
  display: block !important;
  box-sizing: border-box !important;
  color: var(--badgeColor);
}

.membershipIntroTitle {
  font-size: 18px;
  margin-bottom: 4px;
}

.membershipIntroDescription {
  @apply --marketplaceMessageFontStyles;
  margin:0px;
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.basicInfoContainer {
  margin-top: 32px;
  margin-bottom: 32px;
}

.basicInfoRow {
  min-width: 100%;
  flex: 1 1 50%;
  box-sizing: border-box;
  -webkit-box-align: center;
  display: flex;
  margin-bottom: 14px;
  margin-top:14px;
  padding-right: 16px;
  -webkit-align-items: center !important;
  align-items: center !important;

  @media (--viewportMedium) {
    min-width: auto !important;
    /* max-width: 50% !important; */
    margin-top:0;
  }

  @media (--viewportLarge) {
    /* border-top: 1px solid var(--matterColorNegative); */
    margin-top:14px;
    margin-bottom: 12px;
  }
}

.basicInfoIcon {
  display: block !important;
  height: 16px !important;
  width: 16px !important;
  box-sizing: border-box !important;
}

.basicInfoTitle {
  @apply --marketplaceMessageFontStyles;

  margin-left: 16px !important;
  display: inline-block !important;
  box-sizing: border-box;
  white-space: nowrap;
}





/* Referral */
.referralContainer {
  /* border-top: var(--borderTop);
  margin-top: 48px; */
  border-bottom: var(--normalBorder);
  margin-bottom: 48px;
  margin-top: 0px;
  padding-bottom: 18px;
}

.referralMobileHeader {
  @apply --marketplaceH3FontStyles;
  margin-top: 36px;
  /* margin-top: 48px; */

  @media (--viewportLarge) {
    display:none;
  }
}

.referralHeader {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 0 24px;
  }
}

.referralBoardContainer {
  border: var(--normalBorder);
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  padding: 24px;
  /* margin-top: 32px; */
  background: var(--matterColorReminderBackground);
  margin-bottom: 24px;
}

.referralBoard {
  flex: 50%;
  text-align: center;
  /* padding: 12px; */
}

.referralBoard:first-child {
  border-right: var(--normalBorder);
}

.referralBoardValue {
  margin-bottom: 0px;
  margin-top: 0px;
}

.referralBoardTitle {
  margin-top: 2px;
  font-size: 16px;
  color: var(--matterColorDiscount);
  font-weight: 400;
  margin-bottom: 0px;
}

.logo {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-bottom: 3px;
}

.text {
  color: var(--matterColorDark);
  font-size: 16px;
  margin-top: 12px;
  transition: opacity 0.3s;
  cursor: pointer;
  
  &:hover {
    color: var(--marketplaceColor);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  color: var(--marketplaceColorDark);
  margin-top: 12px;
  transition: opacity 0.3s;
  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  /* width: 140px; */
  background-color: var(--marketplaceColorDark);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 10px 15px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 18%;
  margin-left: -30px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  font-size: 16px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--marketplaceColorDark) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.highlightedText {
  font-weight: 600;
  color: var(--marketplaceColorDark);
  font-size: 18px;
}


