@import '../../marketplace.css';

.title {
  @apply --marketplaceH2FontStyles;

  margin-top: 8px;
  max-width: 735px;
  position: absolute;

  @media (--viewportMedium) {
    /* display:none; */
    font-size: 28px;
    margin-top: 16px;
  }
}

.sectionRow {
  /* @apply --clearfix; */
  /* border-top: 1px solid var(--matterColorNegative); */
  border-top: 0px;
  border-bottom: 0px;
  padding: 0 !important;

  /* padding-bottom: 48px;
  padding-top:48px; */

  @media (--viewportMedium) {
    padding: 8px 0 !important;
  }
}

/* .locationsWrapper::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
  /* display: none; */
}

.locationsWrapper {
  display: flex;
  width: 100%;
  /* display: -webkit-box; */
  flex-wrap: nowrap;
  /* overflow-x: auto;
  overflow-y: hidden; */
  padding: 0 0 20px;
  /* margin-left: -5px; */
  padding-top: 64px;
  /* margin-bottom: -20px; */
  
  @media (--viewportMedium) {
    padding-top: 80px;
  }

}

.locations {
  display: flex;
  /* flex-direction: column; */
  /* margin-top: 18px; */
  min-width: 100%;
  /* overflow-x: scroll;
  overflow-y: hidden; */
  
  margin-left: -10px;
  margin-right: -10px;
  
  @media (--viewportMedium) {
    overflow-x: unset;
    overflow-y: unset;
    flex-direction: row;
    justify-content: center;

    /* margin-top: 57px; */
  }
}

.location {
  width: 100%;
  /* margin-top: 25px; */
  height: 100% !important;
  /* margin: 5px; */
  min-width: 87.5%;
  max-width: 87.5%;
  /* padding: 5px; */
  /* padding-right: 20px; */
  padding-left: 10px;
  padding-right: 10px;

  @media (--viewportMedium) {
    min-width: 33.333%;
    max-width: 33.333%;
  }


}

.locationWrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 16px !important;
  box-shadow: var(--boxShadowPromotion);

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  /* border-radius: 4px; */
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  /* padding-bottom: calc(6 / 13 * 100%); 13:6 Aspect Ratio */
  padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */

   /* 3:2 Aspect Ratio
  /* @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
  } */
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 4px; */
  object-fit: cover;
}

.linkText {
  /* @apply --marketplaceH2FontStyles; */
  
  /* margin-top: 15px;
  margin-bottom: 0; */
  padding: 12px 16px 16px !important;
/*   
  @media (--viewportMedium) {
    margin-top: 21px;
  } */
}

.locationName {
  color: var(--marketplaceColor);
}

.sectionTitle {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  line-height: 28px;
}

.sectionDescription {
  @apply --marketplaceSmallFontStyles;
  color: var(--matterColorSubTitle);
  font-weight: 400;
}