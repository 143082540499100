@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.aside {
  box-shadow: none;
  @media (--viewportLarge) {
   padding-right: 40px;
  }
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--matterColorNegative);
  flex-wrap: wrap;
  padding-bottom: 48px;
  padding-top: 12px;

  @media (--viewportLarge) {
    padding-top: 0px;
    padding-bottom: 0px;
    flex-direction: column;
    border-bottom: none;
    position: sticky;
    top: 102px;
  }
}




/* Avatar Information */
.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 96px 0px 0;
  }
}

.tagline {

  @apply --marketplaceH4FontStyles;
  color: var(--matterColorSubTitle);
  font-weight: 400;
  padding-top: 8px;
  margin-top: 4px;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    margin-top:15px;
    max-width: 270px;
    padding-bottom: 8px;
    margin-bottom: 10px;
  }

}

.verificationContainer {
  width: 100%;
  margin-bottom: -12px;
  margin-top: 26px;

  @media (--viewportLarge) {
    padding-top: 34px;
    margin-top: 0px;
    border-top: 1px solid #EBEBEB !important;
    margin-bottom: 24px;
  }
}

.verificationHeader {
  @apply --marketplaceH3FontStyles;
  /* margin-top: 10px; */
  margin-bottom: 24px;
}

.reportAuthor {

  @apply --marketplaceH5FontStyles;
  text-decoration: underline;
  border-top: 1px solid #EBEBEB !important;
  padding-top: 28px;
  font-weight: 600;
  width: 100%;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    border-top: 0px !important;
    padding-top: 32px;
    font-weight: 600;
    margin-bottom: 48px;
  }

}

.reportAuthorHyperlink {
  color: var(--matterColor);
}

.badge {
  color: var(--matterColorLight);
  border-radius: 4px;
  background: var(--marketplaceColor);
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0;
  padding: 4px 10px;
  display: inline-block;
  margin-right: 12px;
  margin-top: 12px;

  @media (--viewportLarge) {
    margin-top: 20px;
  }
}

.authorBadge {
  background: var(--matterColorDark) !important;
}







/* Heading */
.mobileHeading {
  flex-shrink: 0;
  margin: 47px 0 0 0;
  display: flex;
  flex: 50%;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.authorHeader {
  @apply --marketplaceH1FontStyles;
  display: flex;
  flex: 100%;
  margin-bottom: 0px;
  margin-top: 0px;
}

.editLinkMobile {
  /* margin-top: 17px;
  margin-left: auto; */

  right: 24px;
  position: absolute;
  font-weight: 600;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
    position: absolute;
    right: 0;
    font-size: 16px;
    font-weight: 600;
    /* margin-top: 22px; */
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 24px 0;
  }
}





/* Bio */
.bioHeader {
  @apply --marketplaceH3FontStyles;
  margin-top: 24px;

  @media (--viewportLarge) {
    display:none;
  }
}


.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 24px 0 32px 0;
  @apply --marketplaceMessageFontStyles;

  @media (--viewportMedium) {
    margin: 24px 0 32px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 32px 0;
    /* max-width: 600px; */
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.basicInfoContainer {
  margin-bottom: 48px;
}

.basicInfoRow {
  min-width: 100%;
  flex: 1 1 50%;
  box-sizing: border-box;
  -webkit-box-align: center;
  display: flex;
  margin-bottom: 14px;
  margin-top:14px;
  padding-right: 16px;
  -webkit-align-items: center !important;
  align-items: center !important;

  @media (--viewportMedium) {
    min-width: auto !important;
    /* max-width: 50% !important; */
  }
}

.basicInfoIcon {
  display: block !important;
  height: 20px !important;
  width: 20px !important;
  box-sizing: border-box !important;
}

.basicInfoTitle {
  @apply --marketplaceMessageFontStyles;

  margin-left: 16px !important;
  display: inline-block !important;
  box-sizing: border-box;
}







/* Listing */
.sectionRow {
  /* @apply --clearfix; */
  /* border-top: 1px solid var(--matterColorNegative); */
  border-bottom: var(--normalBorder);
  padding: 48px 0px !important;
  /* padding-bottom: 48px;
  padding-top:48px; */

  /* @media (--viewportMedium) {
    padding: 48px 0px !important;
  } */
}

.listingsTitle {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  position: relative;
  /* padding-left: 16px; */
  margin-top: 0;
  margin-bottom: 28px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.listings {
  @media (--viewportLarge) {
    flex-wrap: wrap;
    overflow-x: visible !important;
    overflow-y: visible !important;
  }
}

.listing {

  @media (--viewportLarge) {
    min-width: 50%;
    max-width: 50%;
    flex: 1 1 50%;
    margin-bottom: 24px;
  }

}

.buttonContainer {
  @media (--viewportLarge) {
    display: none !important;
  }
}





/* Review */
.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  padding-top:30px;
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    /* max-width: 600px; */
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}
