@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
  display: none;
}

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.rootImage {
  @apply --backgroundImage;
}

.root {
  /* @apply --backgroundImage; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  position:relative;
  overflow: hidden;
  /* Text positioning is done with paddings */
}

.videoContainer {
  width: 100%;
  position: absolute;
  height: 100%;
  bottom: 0;
  object-fit: cover;
}

.heroContent {
  z-index:1;
  margin: 48px 24px 24px 24px;

  /* @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  } */

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);

  @apply --SectionHero_animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    margin-bottom: 24px;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceBodyFontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  @apply --SectionHero_animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 48px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  @apply --SectionHero_animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.keywordContainer {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  
}

.keywordTitleContainer {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  margin-top: 6px;
  color: var(--matterColorLight);
  white-space: nowrap;

  display:none;
  @media (--viewportMedium) {
    display: unset;
  }
}

.separaterContainer {
  margin: 6px 14px 0 12px;
  color: var(--matterColorLight);

  display:none;
  @media (--viewportMedium) {
    display: unset;
  }
}

.keywordListContainer {
  display: flex;
  overflow: scroll;
  /* margin-right: -24px;
  margin-left: -24px; */

  @media (--viewportMedium) {
    /* margin-right: unset;
    margin-left: unset; */
    overflow: unset;
    flex-wrap: wrap;
  }
}

.keywordBadge {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorLight);
  border: 1px solid;
  padding: 6px 12px;
  border-radius: 6px;
  margin-right: 12px;
  display: inline-block;
  white-space: nowrap;
  margin-top: 0px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-bottom: 12px;
  }

  &:hover {
    border-color: var(--marketplaceColor);
    background: var(--marketplaceColor);
  }
}