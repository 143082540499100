@import '../../marketplace.css';

.sectionRow {
  display: flex;
  padding-top: 12px;
}

.sectionContentContainer {
  display: flex;
  width: 100%;
  /* margin: auto; */
/* 
  @media (--viewportMedium) {
    flex-wrap: wrap;
  } */

  @media (--viewportLarge) {
    flex-wrap: unset;
  }
  
}

.contentRow {
  display: flex;
  padding-right: 32px;
  align-items: center !important;

  flex: 75%;
  max-width: 75%;
  min-width: 75%;

  @media (--viewportMedium) {
    justify-content: center;
    flex: 33.333%;
    max-width: 33.3333%;
    min-width: 33.3333%;
  }

  /* @media (--viewportLarge) {

  } */
}

.contentIcon {
  height: 52px;
  width: 52px !important;
  padding: 4px;
  margin-right: 18px;
  color: var(--marketplaceColor);
}

.contentTitle {
  @apply --marketplaceH4FontStyles;
  font-weight: 500;
  margin-bottom: 2px;
}

.contentDescription {
  @apply --marketplaceH5FontStyles;
  font-weight: 400;
  margin-top: 0px;
}