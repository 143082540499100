@import '../../marketplace.css';

/* Content is visible as modal layer */
.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    @apply --marketplaceModalRootStyles;
   background-color: rgba(0, 0, 0, 0.5);
   background-image: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      padding: 0;
    }
  }
}

.content {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}
